<script setup lang="ts">
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
interface Props {
  required?: boolean
  disabled?: boolean
  id?: string
  international?: boolean
  mode?: 'auto' | 'international' | 'national'
  label?: string
  hasError?: boolean
  errorMessage?: string
}

export type PhoneObject = {
  country: string
  countryCode: string
  formatted: string
  valid: boolean
  possible: boolean
  nationalNumber: string
  countryCallingCode: string
  number: string
  __countryCallingCodeSource: string
  getMetadata: () => void
}

interface Emits {
  (e: 'validate', v: PhoneObject): void
}

withDefaults(defineProps<Props>(), {
  required: true,
  disabled: false,
  id: '',
  mode: 'international',
  label: '',
})
const emit = defineEmits<Emits>()
const model = defineModel()

function handleValidate(phoneObject: PhoneObject) {
  emit('validate', phoneObject)
}
</script>

<template>
  <div
    class="w-full"
    :class="{
      'cursor-not-allowed opacity-50': disabled,
    }"
  >
    <Label v-if="label" class="mb-2 block" :for="id">
      <slot name="label"> {{ label }}</slot>
    </Label>
    <div>
      <VueTelInput
        v-model="model"
        :mode="mode"
        :disabled="disabled"
        valid-characters-only
        :preferred-countries="['GB']"
        default-country="GB"
        :style-classes="[
          `h-10 !border focus-within:!ring-offset-2 focus-within:!ring-2 !border-input text-sm !rounded-md`,
          {
            'ring-red-500 ring-2 focus-within:ring-offset-0': hasError,
            'ring-ring': !hasError,
          },
        ]"
        :input-options="{
          styleClasses:
            'h-full w-full px-3 bg-background py-2 outline-none border-0',
          required: required,
          id: id,
        }"
        @validate="handleValidate"
      />
    </div>
    <span v-if="hasError && errorMessage" class="mt-1 text-sm text-red-500">{{
      errorMessage
    }}</span>
  </div>
</template>
